import React, {FC, useEffect, useRef, useState} from 'react';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Col, Form, Row} from "react-bootstrap";
import s from '../Form/Form.module.scss'
import c from "classnames"
import classNames from "classnames/bind";
import 'react-datetime/css/react-datetime.css';
import '../Form/yearpicker.scss';

import {connect, ConnectedProps} from "react-redux";
import {setBaujahr, setZustand, setZustandZusatz} from "../../stores/selection/selectionSlice";
import Icon from "../Icon/Icon";

import altbau from "./icons/icon_altbau.png";
import sanierung from "./icons/icon_sanierung.png";
import neubau from "./icons/icon_neubau.png";
import passivhaus from "./icons/icon_passivhaus.png";
import niedrigst from "./icons/icon_niedrigst-energiehaus.png";
import niedrig from "./icons/icon_niedrigenergiehaus.png";
import fuenfcm from "./icons/icon_vollwaerme5.png";
import zehncm from "./icons/icon_vollwaerme10.png";
import iwas from "./icons/icon_vollwaerme15_.png";
import {Client, handleApiError} from "../../Services/ApiService";


// @ts-ignore
const mapState = ({selection}) => ({
    selection,
})

const mapDispatch = {
    setZustand,
    setZustandZusatz,
    setBaujahr,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface FormInputs {
    Zustand: string,
    HouseType: string,
    Baujahr: string,
}

let schema = yup.object().shape({
    Zustand: yup.string().required('Bitte wählen Sie den Zustand aus'),
    HouseType: yup.string().required('Bitte wählen Sie eine Option aus'),
    Baujahr: yup.string().optional(),
});

const ZustandStep: FC<PropsFromRedux & any> = (
    {
        location,
        selection,
        setZustand,
        setZustandZusatz,
        setBaujahr,
        prevStep,
        nextStep
    }
) => {

    const {register, handleSubmit, formState: {errors}, setValue} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    let cl = classNames.bind(s);

    const [zustand, setInternalZustand] = useState('');
    const [houseType, setHouseType] = useState('');
    const [yearSelected, setYearSelected] = useState((new Date(Date.now()).getFullYear()).toString());

    const neubautypRef = useRef<HTMLInputElement>(null);
    const sanierungtypRef = useRef<HTMLInputElement>(null);
    const baujahrRef = useRef<HTMLInputElement>(null);


    useEffect(() => {
        setValue('Zustand', zustand);
        if (!selection.Zustand || (selection.Zustand !== zustand && zustand !== '')) {
            setZustand(zustand);
        }
    }, [zustand]);

    useEffect(() => {
        setValue('HouseType', houseType);
        if (!selection.ZustandZusatz || (selection.ZustandZusatz !== houseType && houseType !== '')) {
            setZustandZusatz(houseType)
        }
    }, [houseType]);

    useEffect(() => {
        setValue('Baujahr', yearSelected);
        setBaujahr(yearSelected);
    }, [yearSelected]);

    useEffect(() => {
        if (selection.ZustandZusatz || selection.Zustand) {
            if (selection.Zustand) {
                setInternalZustand(selection.Zustand)
            }
            if (selection.ZustandZusatz) {
                setHouseType(selection.ZustandZusatz)
            }
        }
        setYearSelected(getInitialVal())
    }, [])

    function submitStep(data: any) {
        // @ts-ignore
        window._mtm.push({"event": "step2"});
        nextStep();
    }

    let getInitialVal = () => {
        if (selection.Baujahr) {
            return (selection.Baujahr).toString();
        } else {
            return (new Date(Date.now()).getFullYear()).toString()
        }
    }

    const baujahrArray = [
        {
            title: 'Bausubstanz unsaniert 1965-1974',
            value: '1965'
        },
        {
            title: 'Bausubstanz unsaniert 1975-1979',
            value: '1975'
        },
        {
            title: 'Bausubstanz unsaniert 1980-1989',
            value: '1980'
        },
        {
            title: 'Bausubstanz unsaniert 1990-1994',
            value: '1990'
        },
        {
            title: 'Bausubstanz unsaniert 1995-1999',
            value: '1995'
        },
        {
            title: 'Bausubstanz unsaniert ab 2000',
            value: '2000'
        },
    ];

    const saniertAltbauArray = [
        {
            houseType: "1",
            title: "Mit 5 cm Vollwärmeschutz",
            icon: fuenfcm
        },
        {
            houseType: "2",
            title: "Mit 10 cm Vollwärmeschutz",
            icon: zehncm
        },
        {
            houseType: "3",
            title: "Mit 15 cm Vollwärmeschutz",
            icon: iwas
        }
    ];

    const [zustandTitle, setZustandTitle] = useState('');
    const [zustandDesc, setZustandDesc] = useState('');
    const [zustandNeuDesc, setZustandNeuDesc] = useState('');
    const [zustandAltDesc, setZustandAltDesc] = useState('');
    const [zustandSanDesc, setZustandSanDesc] = useState('');
    useEffect(() => {
        Client.Raw.post('getText/zustand').then((res) => {
            setZustandTitle(res.data.Title);
            setZustandDesc(res.data.Desc);
            setZustandNeuDesc(res.data.ZustandNeuDesc);
            setZustandAltDesc(res.data.ZustandAltDesc);
            setZustandSanDesc(res.data.ZustandSanDesc);
        }).catch(handleApiError);
    }, [])

    return (
        <Form onSubmit={handleSubmit(submitStep)}>
            <Row className={c(s.step)}>
                <Col xs={12} md={12} className={c("align-self-center")}>
                    <Row>
                        <Col xs={12} className={c("text-center")}>
                            <h2 className={c(s.title)}>{zustandTitle}</h2>
                            <Form.Label className={c("col-form-label mb-4", s.label)}>
                                {zustandDesc}
                            </Form.Label>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} md={4} lg={4}>
                                    <div className={c(s.choice) + ' ' + cl({
                                        active: zustand === "1"
                                    })} onClick={() => {
                                        setInternalZustand("1");
                                        setHouseType('');
                                        neubautypRef.current?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "nearest",
                                        });
                                    }}>
                                        <Icon image={neubau}/>
                                        <div>Neubau</div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className={c(s.choice) + ' ' + cl({
                                        active: zustand === "2"
                                    })} onClick={() => {
                                        setInternalZustand("2");
                                        setHouseType('');
                                        sanierungtypRef.current?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "nearest",
                                        });
                                    }}>
                                        <Icon image={sanierung}/>
                                        <div>Sanierter Altbau</div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <div className={c(s.choice) + ' ' + cl({
                                        active: zustand === "3"
                                    })} onClick={() => {
                                        setInternalZustand("3")
                                        setHouseType("0")
                                    }}>
                                        <Icon image={altbau}/>
                                        <div>Unsanierter Altbau</div>
                                    </div>
                                </Col>
                            </Row>
                            <Form.Control type="hidden" value={houseType} {...register("Zustand")}/>
                            <Form.Text className={s.errorMsg}>
                                {errors.Zustand?.message}
                            </Form.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {
                zustand === "1" ?
                    <>
                        <div ref={sanierungtypRef}/>
                        <Row className={c(s.step, s.hidden)}>
                            <Col xs={12} md={12} className="align-self-center">
                                <Row>
                                    <Col xs={12} className={c("text-center")}>
                                        <Form.Label className={c("col-form-label my-4", s.label)}>
                                            {zustandNeuDesc}
                                        </Form.Label>
                                    </Col>
                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={12} md={4} lg={4}>
                                                <div className={c(s.choice) + ' ' + cl({
                                                    active: houseType === "1"
                                                })} onClick={() => {
                                                    setHouseType("1");
                                                }}>
                                                    <Icon image={passivhaus}/>
                                                    <div>Passivhaus</div>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={4} lg={4}>
                                                <div className={c(s.choice) + ' ' + cl({
                                                    active: houseType === "2"
                                                })} onClick={() => {
                                                    setHouseType("2");
                                                }}>
                                                    <Icon image={niedrigst}/>
                                                    <div>Niedrigstenergiehaus</div>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={4} lg={4}>
                                                <div className={c(s.choice) + ' ' + cl({
                                                    active: houseType === "3"
                                                })} onClick={() => {
                                                    setHouseType("3");
                                                }}>
                                                    <Icon image={niedrig}/>
                                                    <div>Niedrigenergiehaus</div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Form.Control type="hidden"
                                                      value={houseType} {...register("HouseType")}/>
                                        <Form.Text className={s.errorMsg}>
                                            {errors.HouseType?.message}
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <div ref={sanierungtypRef}/>
                    </>
            }
            {
                zustand === "2" ?
                    <>
                        <div ref={neubautypRef}/>
                        <Row className={c(s.step, s.hidden)}>
                            <Col xs={12} md={12} className="align-self-center">
                                <Row>
                                    <Col xs={12} className={c("text-center")}>
                                        <Form.Label className={c("col-form-label my-4", s.label)}>
                                            {zustandAltDesc}
                                        </Form.Label>
                                    </Col>
                                    <Col xs={12}>
                                        <Row>
                                            {saniertAltbauArray.map((choice, index) => {
                                                return (
                                                    <Col xs={12} md={4} lg={4}>
                                                        <div className={c(s.choice) + ' ' + cl({
                                                            active: houseType === choice.houseType
                                                        })} onClick={() => {
                                                            setHouseType(choice.houseType);
                                                        }}>
                                                            <Icon image={choice.icon}/>
                                                            <div>{choice.title}</div>
                                                        </div>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                        <Form.Control type="hidden" value={houseType} {...register("HouseType")}/>
                                        <Form.Text className={s.errorMsg}>
                                            {errors.HouseType?.message}
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <div ref={neubautypRef}/>
                    </>
            }
            {
                zustand === "3" ? <>
                        <Row className={c(s.step)}>
                            <div ref={baujahrRef}/>
                            <Col xs={12} md={12}>
                                <Row className={c("justify-content-center")}>
                                    <Col xs={12}>
                                        {/*<h2 className={c(s.title)}>Baujahr</h2>*/}
                                        <Form.Label className={c("col-form-label my-4", s.label)}>
                                            {zustandSanDesc}
                                        </Form.Label>
                                    </Col>
                                    {baujahrArray.map((choice, index) => {
                                        return (
                                            <Col xs={12} md={4} lg={4} className={"mb-4"}>
                                                <div className={c(s.choice) + ' ' + cl({
                                                    active: choice.value === yearSelected
                                                })} onClick={() => {
                                                    setYearSelected(choice.value)
                                                }}>
                                                    <div>{choice.title}</div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                                <Form.Text className={s.errorMsg}>
                                    {errors.Baujahr?.message}
                                </Form.Text>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <div ref={baujahrRef}/>
                    </>
            }
            <Row className={c("justify-content-center")}>
                <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder, "order-md-last")}>
                    <Button className={c(s.nextButton, s.button)} type="submit">
                        Weiter
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default connector(ZustandStep);
