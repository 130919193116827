import * as React from 'react';
import {FC, useEffect, useRef, useState} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import Container from 'react-bootstrap/esm/Container';
import style from './Result.module.scss'
import './Result.css'
import gs from "../../Theme/common.module.scss";
import c from 'classnames'
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressProvider from "./ProgressProvider";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {useNavigate, useParams} from 'react-router-dom';
import FullscreenLoading from "../../Components/FullscreenLoader/FullscreenLoading";
import {Client, handleApiError} from "../../Services/ApiService";
import {PDFDownloadLink} from '@react-pdf/renderer';
import KostenPDF from '../../Components/PDF/KostenPDF';
import {SquareLoader} from "react-spinners";
import Switch from "react-switch";
import {resetState} from '../../stores/selection/selectionSlice';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
//@ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect, ConnectedProps} from "react-redux";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// @ts-ignore
const mapState = ({selection}) => ({
    selection,
})

const mapDispatch = {
    resetState
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


const Result: FC<PropsFromRedux & any> = ({location, selection, resetState, setStep}, props) => {

    const params = useParams();

    const [heizlast, setHeizlast] = useState<any>('');
    const [kesseleistung, setKesseleistung] = useState<any>('');
    const [heizArray, setHeizArray] = useState<any>([]);
    const [wasserArray, setWasserArray] = useState<any>([]);
    const [heizArrayDecade, setHeizArrayDecade] = useState<any>([]);
    const [wasserArrayDecade, setWasserArrayDecade] = useState<any>([]);
    const [baseImageLoading, setBaseImageLoading] = useState(true);

    const [heizsystemSummary, setHeizsystemSummary] = useState('');
    const [areaSummary, setAreaSummary] = useState('');
    const [personSummary, setPersonSummary] = useState('');
    const [stateSummary, setStateSummary] = useState('');
    const [stateTypeSummary, setStateTypeSummary] = useState('');
    const [yearSummary, setYearSummary] = useState('');
    const [materialSummary, setMaterialSummary] = useState('');
    const [energiebedarfSummary, setEnergiebedarfSummary] = useState('');

    const [dataBrennstoffKosten, setDataBrennstoffKosten] = useState([]);
    const [dataWasserKosten, setDataWasserKosten] = useState([]);
    const [dataBrennstoffKostenDecade, setDataBrennstoffKostenDecade] = useState([]);
    const [dataWasserKostenDecade, setDataWasserKostenDecade] = useState([]);
    const [labelsMutliChart, setLabelsMultiChart] = useState([]);
    const [labelsMutliChartDecade, setLabelsMultiChartDecade] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pdfName, setPDFName] = useState("");
    const [loadingPDF, setLoadingPDF] = useState(true);
    const [yearView, setYearView] = useState(true);


    const [based64Heizen, setBased64Heizen] = useState('');
    const [based64HeizenDecade, setBased64HeizenDecade] = useState('');
    const [based64Heizlast, setBased64Heizlast] = useState<any>('');
    const [based64Kessel, setBased64Kessel] = useState<any>('');

    const navigate = useNavigate();
    const handeSwitchYear = () => setYearView(!yearView);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setCopied(false);
    }
    const handleShow = () => setShow(true);

    const [copied, setCopied] = useState(false);

    const chartMultiRef = useRef(null);
    const chartMultiRefDecade = useRef(null);
    const downloadBtn = useRef<HTMLButtonElement>(null)

    const generateBasedImages = () => {

        const chartMulti: any = chartMultiRef.current;
        const chartMultiDecade: any = chartMultiRefDecade.current;

        if (chartMulti) {
            setBased64Heizen(chartMulti.toBase64Image());
        }
        if (chartMultiDecade) {
            setBased64HeizenDecade(chartMultiDecade.toBase64Image());
        }
        getSVGIMages('#heizlast svg')?.then(res => {
            setBased64Heizlast(res)
        })
        getSVGIMages('#kessel svg')?.then(res => {
            setBased64Kessel(res)
        })
        if (baseImageLoading) {
            setBaseImageLoading(!baseImageLoading);
        }
    }

    const labelHeiz = 'Heizkosten (€)';
    const labelWass = 'Warmwasserkosten (€)';

    const multiData = {
        labels: labelsMutliChart,
        datasets: [
            {
                label: labelHeiz,
                data: dataBrennstoffKosten,
                borderColor: '#e03131',
                backgroundColor: '#e03131',
                fill: false,
            },
            {
                label: labelWass,
                data: dataWasserKosten,
                borderColor: '#9ec1d7',
                backgroundColor: '#9ec1d7',
                fill: false,
            }
        ],
    };

    const multiDataDecade = {
        labels: labelsMutliChartDecade,
        datasets: [
            {
                label: labelHeiz,
                data: dataBrennstoffKostenDecade,
                borderColor: '#e03131',
                backgroundColor: '#e03131',
                fill: false,
            },
            {
                label: labelWass,
                data: dataWasserKostenDecade,
                borderColor: '#9ec1d7',
                backgroundColor: '#9ec1d7',
                fill: false,
            }
        ],
    };

    const multiConfig = {
        indexAxis: "y" as const,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: "bottom" as const
            },
            tooltip: {
                enabled: true,
            }
        },
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true,
            }
        },
    };

    const multiConfigResponsive = {
        indexAxis: "x" as const,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: "bottom" as const
            },
            tooltip: {
                enabled: true,
            },
            labels: {
                fontSize: 12
            }
        },
        scales: {
            x: {
                stacked: true,
                beginAtZero: true,
                ticks: {
                    font: {
                        size: 9
                    }
                }
            },
            y: {
                stacked: true,
            }
        }
    };

    const config4 = {
        indexAxis: "y" as const,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: "bottom" as const,
            },
            tooltip: {
                enabled: false,
            }
        },
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true,
            }
        },
        animation: {
            onProgress: function (animation: any) {
                if (animation?.numSteps == animation?.currentStep) {
                    generateBasedImages();
                }
            }
        }
    };

    useEffect(() => {
        if (typeof params.hash === "undefined" || params.hash.length === 0) {
            Client.Raw.post('getCalcData/', {
                area: selection.Wohnflaeche,
                year: selection.Baujahr,
                personen: selection.Personenanzahl,
                heizsystem: selection.Heizsystem,
                state: selection.Zustand,
                stateType: selection.ZustandZusatz,
                bedarf: selection.BedarfBewusst,
                material: selection.Heizmaterial,
                energiebedarf: selection.Energiebedarf,
                preise: {
                    Preis_1: selection.PreisHeizoel,
                    Preis_2: selection.PreisErgas,
                    Preis_3: selection.PreisPellet,
                    Preis_4: selection.PreisFluessiggas,
                    Preis_5: selection.PreisStrom,
                }
            }).then((res) => {
                navigate('/result/' + res.data.Hash);
            }).catch(handleApiError)
        } else {
            Client.Raw.get('getConfig/' + params.hash).then((res) => {
                setHeizlast(res.data.Heizlast);
                setKesseleistung(res.data.Kesselleistung);
                setHeizArrayDecade(JSON.parse(res.data.ArrayHeizenLong));
                setWasserArrayDecade(JSON.parse(res.data.ArrayWasserLong));
                setHeizArray(JSON.parse(res.data.ArrayHeizen));
                setWasserArray(JSON.parse(res.data.ArrayWasser));
                setHeizsystemSummary(res.data.Heizsystem);
                setAreaSummary(res.data.Area);
                setPersonSummary(res.data.Personen);
                setStateSummary(res.data.StateOfConstruction);
                setStateTypeSummary(res.data.StateType);
                setYearSummary(res.data.YearOfConstruction);
                setMaterialSummary(res.data.Heizmaterial);
                setEnergiebedarfSummary(res.data.Energiebedarf);
            }).catch(handleApiError)
            let pdfDate = new Date(Date.now()).toLocaleString().split(',')[0]
            setPDFName("Hoval_Heizkosten_" + pdfDate + ".pdf");
        }
    }, [params.hash])

    useEffect(() => {
            getDataForGraphs();
        }, [wasserArray, heizArray]
    )

    const getDataForGraphs = () => {

        let tempBrennstoff: any = [];
        let tempWasser: any = [];
        let tempBrennstoffDecade: any = [];
        let tempWasserDecade: any = [];
        let tempMulti: any = [];
        let tempMultiDecade: any = [];


        for (const [key, value] of Object.entries(heizArray)) {
            tempBrennstoff.push(Math.round(value as number));
            tempMulti.push(key);
        }

        for (const [key, value] of Object.entries(wasserArray)) {
            tempWasser.push(Math.round(value as number));
            tempMulti.push(key);
        }

        for (const [key, value] of Object.entries(heizArrayDecade)) {
            tempBrennstoffDecade.push(Math.round(value as number));
            tempMultiDecade.push(key);
        }

        for (const [key, value] of Object.entries(wasserArrayDecade)) {
            tempWasserDecade.push(Math.round(value as number));
            tempMultiDecade.push(key);
        }

        setDataBrennstoffKosten(tempBrennstoff);
        setDataWasserKosten(tempWasser);
        // @ts-ignore
        setLabelsMultiChart([...new Set(tempMulti)]);
        // @ts-ignore
        setLabelsMultiChartDecade([...new Set(tempMultiDecade)]);
        setDataBrennstoffKostenDecade(tempBrennstoffDecade);
        setDataWasserKostenDecade(tempWasserDecade);

        setLoading(false);
    }

    const getHeizlastPercentage = () => {
        return (((1000 * heizlast) / 41600) * 100);
    }

    const getKesseleistungPercentage = () => {
        return (((kesseleistung * 100) + (heizlast * 1000)) / 41602.5) * 100;
    }

    const restartCaluclation = () => {
        resetState();
        navigate("/", {replace: true});
    }

    const getSVGIMages = (selector: string) => {
        let svg = document.querySelector(selector);
        if (svg) {
            svg.setAttribute('width', '252px');
            svg.setAttribute('height', '252px');
            let svgData = new XMLSerializer().serializeToString(svg);
            let canvas = document.createElement('canvas');
            let svgSize = svg.getBoundingClientRect();

            //Resize can break shadows
            canvas.width = svgSize.width;
            canvas.height = svgSize.height;
            canvas.style.width = svgSize.width + 50 + '';
            canvas.style.height = svgSize.height + 50 + '';
            let ctxt = canvas.getContext('2d');

            if (ctxt) {
                return new Promise(res => {
                    ctxt?.scale(1, 1);
                    let img = document.createElement('img');
                    img.setAttribute('src', 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svgData))));
                    img.onload = function () {
                        ctxt?.drawImage(img, 0, 0, 252, 252);
                        let file = canvas.toDataURL(`image/png`);
                        res(file);
                    }
                });
            }
        }
    }

    const [ergebnisTitle, setErgebnisTitle] = useState('');
    const [ergebnisDesc, setErgebnisDesc] = useState('');
    const [ergebnisButtonPDF, setErgebnisButtonPDF] = useState('');
    const [ergebnisButtonShare, setErgebnisButtonShare] = useState('');
    const [ergebnisButtonNew, setErgebnisButtonNew] = useState('');
    const [ergebnisHeizkostenTitle, setErgebnisHeizkostenTitle] = useState('');
    const [ergebnisHeizlastTitle, setErgebnisHeizlastTitle] = useState('');
    const [ergebnisKesselTitle, setErgebnisKesselTitle] = useState('');
    const [ergebnisLegalNoticeYearOwn, setErgebnisLegalNoticeYearOwn] = useState('');
    const [ergebnisLegalNoticeYearDef, setErgebnisLegalNoticeYearDef] = useState('');
    const [ergebnisLegalNoticeDecade, setErgebnisLegalNoticeDecade] = useState('');

    const getCurrentDateFormatted = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd: number = today.getDate();

        return (((dd < 10) ? ('0' + dd) : dd) + '.' + ((mm < 10) ? ('0' + mm) : mm) + '.' + yyyy);
    };

    useEffect(() => {
        Client.Raw.post('getText/ergebnis').then((res) => {
            setErgebnisTitle(res.data.Title);
            setErgebnisDesc(res.data.Desc);
            setErgebnisButtonPDF(res.data.ButtonPDF);
            setErgebnisButtonShare(res.data.ButtonShare);
            setErgebnisButtonNew(res.data.ButtonNew);
            setErgebnisHeizkostenTitle(res.data.HeizkostenTitle);
            setErgebnisHeizlastTitle(res.data.HeizlastTitle);
            setErgebnisKesselTitle(res.data.KesselTitle);
            setErgebnisLegalNoticeYearOwn(res.data.LegalNoticeYearOwn.slice(0, res.data.LegalNoticeYearOwn.search('{yesterday}')) + ' ' + getCurrentDateFormatted());
            setErgebnisLegalNoticeYearDef(res.data.LegalNoticeYearDef.slice(0, res.data.LegalNoticeYearDef.search('{yesterday}')) + ' ' + getCurrentDateFormatted());
            setErgebnisLegalNoticeDecade(res.data.LegalNoticeDecade);
        }).catch(handleApiError);
    }, [])

    const pdfContent = <KostenPDF based64heizen={based64Heizen}
                                  based64wasser={based64HeizenDecade}
                                  based64heizlast={based64Heizlast}
                                  based64kessel={based64Kessel}
                                  heizlast={(heizlast.toString()).replace('.', ',')}
                                  kesselleistung={(kesseleistung.toString()).replace('.', ',')}
                                  text={'Die angegebenen Werte sind als Richtwerte zu verstehen'}
                                  loadingCallback={setLoadingPDF}
                                  heizsystemSummary={heizsystemSummary}
                                  areaSummary={areaSummary}
                                  personSummary={personSummary}
                                  stateSummary={stateSummary}
                                  stateTypeSummary={stateTypeSummary}
                                  yearSummary={yearSummary}
                                  materialSummary={materialSummary}
                                  energiebedarfSummary={energiebedarfSummary}
                                  ergebnisLegalNoticeYearOwn={ergebnisLegalNoticeYearOwn}
                                  ergebnisLegalNoticeDecade={ergebnisLegalNoticeDecade}
    />;

    return (
        <>
            {/*{params.hash && <StepMenu setDisplayStep={setStep}/>}*/}
            <Container>
                {loading ?
                    <FullscreenLoading loading={loading}/> :
                    <>
                        <Row className="pt-5">
                            <Col xs={12} lg={12} xl={3}>
                                <div className={style.title}>{ergebnisTitle}</div>
                            </Col>
                            <Col xs={12} lg={4} xl={3} className={c("mb-xs-0 mb-3")}>
                                {
                                    loadingPDF ? <></> :
                                        <Button onClick={() => downloadBtn.current?.click()}
                                                className={c(gs.bigRedButton, style.maxWidth)}>
                                            {ergebnisButtonPDF}
                                        </Button>
                                }
                            </Col>
                            <Col xs={6} lg={4} xl={3} className="d-flex d-lg-block">
                                {
                                    loadingPDF ? <SquareLoader color="#e2001a"/> :
                                        <Button onClick={handleShow} className={c(gs.bigRedButton, style.maxWidth)}>
                                            {ergebnisButtonShare}
                                        </Button>
                                }
                                <Modal show={show} onHide={handleClose} animation={false}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Ergebnis Teilen</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p className="mb-2"> Klicke einfach den Link, um ihn zu kopieren und an Freunde
                                            und Familie weiterzuleiten. </p> <br/>
                                        <CopyToClipboard text={window.location.href} onCopy={() => {
                                            navigator?.clipboard?.writeText(window.location.href)
                                            setCopied(true)
                                        }}>
                                            <span
                                                className={c(style.copyToClipboard, copied ? style.copied : style.notcopied)}>
                                                {
                                                    copied ?
                                                        <><span className={style.copytext}>Kopiert.</span></> :
                                                        <><span
                                                            className={style.link}>{window.location.href.substring(0, 40) + '... '}</span></>
                                                }
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                     data-icon="clipboard"
                                                     className="svg-inline--fa fa-clipboard fa-w-12 Navigation_icon__gz5iE"
                                                     role="img" xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 384 512"
                                                     width="0.75em">
                                                    <path fill="currentColor"
                                                          d="M384 112v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h80c0-35.29 28.71-64 64-64s64 28.71 64 64h80c26.51 0 48 21.49 48 48zM192 40c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24m96 114v-20a6 6 0 0 0-6-6H102a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h180a6 6 0 0 0 6-6z"/>
                                                </svg>
                                            </span>
                                        </CopyToClipboard>
                                        <div className={'pt-3'}>
                                            <FacebookShareButton className={'pe-3'}
                                                                 quote={'Meine Heizkosten berechnet mit Hoval-Heizkostenrechner: '}
                                                                 url={window.location.href}>
                                                <FacebookIcon round={true}/>
                                            </FacebookShareButton>
                                            <WhatsappShareButton className={'pe-3'}
                                                                 title={'Meine Heizkosten berechnet mit Hoval-Heizkostenrechner: '}
                                                                 url={window.location.href}>
                                                <WhatsappIcon round={true}/>
                                            </WhatsappShareButton>
                                            <TelegramShareButton className={'pe-3'}
                                                                 title={'Meine Heizkosten berechnet mit Hoval-Heizkostenrechner: '}
                                                                 url={window.location.href}>
                                                <TelegramIcon round={true}/>
                                            </TelegramShareButton>
                                            <EmailShareButton className={'pe-3'}
                                                              subject={'Meine Heizkosten berechnet mit Hoval-Heizkostenrechner'}
                                                              body={'Meine Heizkosten berechnet mit Hoval-Heizkostenrechner: '}
                                                              url={window.location.href}>
                                                <EmailIcon round={true}/>
                                            </EmailShareButton>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={handleClose} className={c(gs.bigRedButton, style.maxWidth)}>
                                            Schließen
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Col>
                            <Col xs={6} lg={4} xl={3}>
                                <div onClick={restartCaluclation}
                                     className={c(gs.bigGrayButton, style.maxWidth, "btn")}>
                                    {ergebnisButtonNew}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div style={{paddingTop: 25}} dangerouslySetInnerHTML={{__html: ergebnisDesc}}/>
                            </Col>
                        </Row>
                        <Row className="my-5">
                            <Col xs="12" lg="6">
                                <div className={style.grayBg}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                    }}>
                                        <div className={style.subtitleRed}>
                                            {ergebnisHeizkostenTitle}
                                        </div>
                                        <div style={{marginLeft: "auto", paddingBottom: 10}}>
                                            <div style={{display: "inline", marginRight: 15, fontWeight: 700}}>Ansicht
                                                umschalten:
                                            </div>
                                            <Switch
                                                onChange={handeSwitchYear}
                                                checked={!yearView}
                                                offColor={'#282c34'}
                                                onColor={'#dd1620'}
                                                uncheckedIcon={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            fontSize: 16,
                                                            fontWeight: 700,
                                                            color: "#FFF",
                                                            paddingRight: 2,
                                                            paddingBottom: 2
                                                        }}
                                                    >
                                                        1
                                                    </div>
                                                }
                                                checkedIcon={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            fontSize: 16,
                                                            fontWeight: 700,
                                                            color: "#FFF",
                                                            paddingLeft: 2,
                                                            paddingBottom: 2
                                                        }}
                                                    >
                                                        10
                                                    </div>
                                                }
                                            />
                                            <div style={{display: "inline-block", width: 50, marginLeft: 5}}>
                                                {
                                                    yearView ? <>Jahr</> : <>Jahre</>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        yearView ?
                                            <div>
                                                <div className={style.calcSub}>
                                                    Heiz- und Warmwasserkosten / Jahr (in €)
                                                </div>
                                                <div className={c("d-none d-lg-block")}>
                                                    {/*// @ts-ignore*/}
                                                    <Bar className={c("canvas-container")} options={multiConfig}
                                                         data={multiData}/>
                                                </div>
                                                <div className={c("d-block d-lg-none")}>
                                                    {/*// @ts-ignore*/}
                                                    <Bar className={c("canvas-container-responsive mt-3")}
                                                         options={multiConfigResponsive} data={multiData}/>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className={style.calcSub}>
                                                    (auf Basis der geglätteten Mittelwerte aus 10 Jahren)
                                                </div>
                                                <div className={c("d-none d-lg-block")}>
                                                    {/*// @ts-ignore */}
                                                    <Bar className={c("canvas-container")} options={multiConfig}
                                                         data={multiDataDecade}/>
                                                </div>
                                                <div className={c("d-block d-lg-none")}>
                                                    {/*// @ts-ignore */}
                                                    <Bar className={c("canvas-container-responsive mt-3")}
                                                         options={multiConfigResponsive} data={multiDataDecade}/>
                                                </div>
                                            </div>
                                    }
                                    <div className={style.hiddenRefChartHolder}>
                                        <div className={style.hiddenRefChart}>
                                            <Bar ref={chartMultiRef} className={style.chartSize} options={config4}
                                                 data={multiData}/>
                                            <Bar ref={chartMultiRefDecade} className={style.chartSize} options={config4}
                                                 data={multiDataDecade}/>
                                        </div>
                                    </div>
                                    <div className={c(style.caption, "pt-4")}>
                                        {!yearView ? ergebnisLegalNoticeDecade : ergebnisLegalNoticeYearOwn}
                                    </div>
                                </div>
                                <PDFDownloadLink className={c("d-none d-lg-block")}
                                                 document={pdfContent} fileName={pdfName}>
                                    {
                                        ({blob, url, loading, error}) =>
                                            loading ? <SquareLoader color="#e2001a"/> :
                                                <Button ref={downloadBtn}
                                                        className={c(gs.bigRedButton, style.maxWidth)}>
                                                    {ergebnisButtonPDF}
                                                </Button>
                                    }
                                </PDFDownloadLink>
                                {(heizsystemSummary ||
                                    areaSummary ||
                                    personSummary ||
                                    stateSummary ||
                                    stateTypeSummary ||
                                    materialSummary ||
                                    energiebedarfSummary ||
                                    yearSummary) && <div className={"pt-5"}>
									<div className={style.subtitleRed}>Ihre Angaben</div>
									<table className={"table-borderless table"}>
                                        {heizsystemSummary && <tr>
											<th>Heizsystem:</th>
											<td className={"px-2"}>{heizsystemSummary}</td>
										</tr>}
                                        {areaSummary && <tr>
											<th>Fläche:</th>
											<td className={"px-2"}>{areaSummary}m²</td>
										</tr>}
                                        {personSummary && <tr>
											<th>Personen:</th>
											<td className={"px-2"}>{personSummary}</td>
										</tr>}
                                        {stateSummary && <tr>
											<th>Zustand:</th>
											<td className={"px-2"}>{stateSummary}</td>
										</tr>}
                                        {stateTypeSummary && <tr>
											<th> Bauweise:</th>
											<td className={"px-2"}>{stateTypeSummary}</td>
										</tr>}
                                        {yearSummary && <tr>
											<th> Baujahr:</th>
											<td className={"px-2"}>{yearSummary}</td>
										</tr>}
                                        {materialSummary !== 'Keine Angabe' && <tr>
											<th> Heizmaterial:</th>
											<td className={"px-2"}>{materialSummary}</td>
										</tr>}
                                        {energiebedarfSummary !== 'Keine Angabe' && <tr>
											<th> Energiebedarf:</th>
											<td className={"px-2"}>{energiebedarfSummary}</td>
										</tr>}
									</table>
								</div>}
                            </Col>
                            <Col xs="12" lg="6" className={style.noBg}>
                                <Row>
                                    <Col xs="12" md="6" lg="12">
                                        <div className={style.circleHolder}>
                                            <div
                                                className={c(style.circleTitle, style.red)}>{ergebnisHeizlastTitle}</div>
                                            <div className={c(style.chartHolder)} style={{width: 252, height: 252}}
                                                 id="heizlast">
                                                <ProgressProvider valueStart={0}
                                                                  valueEnd={(getHeizlastPercentage() / 100)}
                                                >
                                                    {
                                                        (value: any) =>
                                                            <CircularProgressbarWithChildren
                                                                value={getHeizlastPercentage()}
                                                                styles={{
                                                                    root: {
                                                                        width: '252px',
                                                                        height: '252px',
                                                                    },
                                                                    path: {
                                                                        stroke: `#e2001a`,
                                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                                        transformOrigin: 'center center',
                                                                    },
                                                                    trail: {
                                                                        stroke: '#d6d6d6',
                                                                        strokeLinecap: 'butt',
                                                                        transform: 'rotate(0.25turn)',
                                                                        transformOrigin: 'center center',
                                                                    },
                                                                    text: {
                                                                        fill: '#e2001a',
                                                                        fontSize: '12px',
                                                                    },
                                                                }}
                                                            >
                                                                <div className={c(style.circleValue, style.red)}>
                                                                    <strong> {heizlast.toString().replace('.', ',')} </strong><span>kW</span>
                                                                </div>
                                                            </CircularProgressbarWithChildren>
                                                    }
                                                </ProgressProvider>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="12" md="6" lg="12" className={c("mt-5 mt-md-0 mt-lg-5")}>
                                        <div className={style.circleHolder}>
                                            <div className={style.circleTitle}>
                                                {ergebnisKesselTitle}
                                            </div>
                                            <div className={style.chartHolder} style={{width: 252, height: 252}}
                                                 id="kessel">
                                                <ProgressProvider valueStart={0}
                                                                  valueEnd={(getKesseleistungPercentage() / 100)}>
                                                    {(value: any) => <CircularProgressbarWithChildren
                                                        value={getKesseleistungPercentage()}
                                                        styles={{
                                                            root: {
                                                                width: '252px',
                                                                height: '252px',
                                                            },
                                                            path: {
                                                                stroke: `#282c34`,
                                                                transition: 'stroke-dashoffset 0.5s ease 0s',
                                                                transformOrigin: 'center center',
                                                            },
                                                            trail: {
                                                                stroke: '#d6d6d6',
                                                                strokeLinecap: 'butt',
                                                                transform: 'rotate(0.25turn)',
                                                                transformOrigin: 'center center',
                                                            },
                                                            text: {
                                                                fill: '#282c34',
                                                                fontSize: '16px',
                                                            },
                                                        }}
                                                    >
                                                        <div className={style.circleValue}>
                                                            <strong> {(kesseleistung.toString()).replace('.', ',')}</strong><span>kW</span>
                                                        </div>
                                                    </CircularProgressbarWithChildren>}
                                                </ProgressProvider>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} className={c("d-block d-lg-none")}>
                                {loadingPDF ? <></> :
                                    <Button onClick={() => downloadBtn.current?.click()}
                                            className={c(gs.bigRedButton, style.maxWidth)}>
                                        {ergebnisButtonPDF}
                                    </Button>
                                }
                            </Col>
                            <Col xs="2"/>
                        </Row>
                    </>
                }
            </Container>
        </>
    );
}

export default connector(Result);
