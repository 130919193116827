import React, {useEffect, useState} from "react";
import {SquareLoader} from "react-spinners";
import {Route, Routes} from "react-router-dom";
import Form from "../../Components/Form/Form";
import Result from "../Result/Result";

function Main() {

    return (
        <>
            <Routes>
                <Route path={'/'} element={<Form/>}/>
                <Route path={'/result/:hash'} element={<Result/>}/>
            </Routes>
        </>
    );
}

export default Main;
