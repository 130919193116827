import React, {FC} from "react";

type ProgressProps = {
    valueStart: any,
    valueEnd: any,
    children: any,
}

// If you don't have a version of React that supports
// hooks, you can use a class-based version of this
// component in ProgressProviderUsingClass.js
const ProgressProvider: FC<ProgressProps> = ({valueStart, valueEnd, children}) => {
    const [value, setValue] = React.useState(valueStart);
    React.useEffect(() => {
        setValue(valueEnd);
    }, [valueEnd]);

    return children(value);
};
export default ProgressProvider;
