import selectionReducer from './selection/selectionSlice';
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import {persistReducer, persistStore} from 'redux-persist'

export default () => {
    const persistConfig = {
        key: 'rootNew',
        storage,
    }

    const rootReducer = combineReducers({
        selection: selectionReducer,
    })

    const persistedReducer = persistReducer(persistConfig, rootReducer)

    const store = configureStore({
        reducer: persistedReducer,
    });

    let persistor = persistStore(store)

    return {store, persistor}
}
