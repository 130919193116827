import {Col, Form, InputGroup, Row} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import React, {FC, useEffect, useState} from "react";
import s from '../Form/Form.module.scss'
import c from "classnames"
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {setPersonenanzahl} from "../../stores/selection/selectionSlice";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../Services/ApiService";


// @ts-ignore
const mapState = ({selection}) => ({
    selection,
})

const mapDispatch = {
    setPersonenanzahl,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface FormInputs {
    Personen: number,
}

let schema = yup.object().shape({
    Personen: yup.number().min(0, 'Bitte geben Sie die Personenanzahl an').required(),
});

const PersonenStep: FC<PropsFromRedux & any> = ({location, selection, setPersonenanzahl, prevStep, nextStep}) => {

    const {register, handleSubmit, formState: {errors}, setValue} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });
    const [persons, setPersons] = useState(2);

    useEffect(() => {
        setValue('Personen', persons);
        setPersonenanzahl(persons);
    }, [persons]);

    useEffect(() => {
        if (selection.Personenanzahl) {
            setPersons(selection.Personenanzahl)
        }
    }, []);


    function submitStep(data: any) {
        // @ts-ignore
        window._mtm.push({"event": "step5"});
        nextStep();
    }

    function handleInputChange(event: any) {
        if (event.target.value === '') {
            setPersons(2);
        } else {
            if (event.target.value > 10 || event.target.value < 0) {
                setPersons(4)
            } else {
                setPersons(parseInt(event.target.value))
            }
        }
    }

    const [personenTitle, setPersonenTitle] = useState('');
    const [personenDesc, setPersonenDesc] = useState('');
    useEffect(() => {
        Client.Raw.post('getText/personen').then((res) => {
            setPersonenTitle(res.data.Title);
            setPersonenDesc(res.data.Desc);
        }).catch(handleApiError);
    }, [])

    return (
        <Form onSubmit={handleSubmit(submitStep)}>
            <Row className={c(s.step)}>
                <Col xs={12} md={12}>
                    <Row>
                        <Col xs={12}>
                            <h2 className={c(s.title)}>{personenTitle}</h2>
                            <Form.Label className={"col-form-label mb-4"}>
                                <div style={{whiteSpace: 'pre-wrap'}}>{personenDesc}</div>
                            </Form.Label>
                        </Col>
                        <Col xs={12}>
                            <Row className={c("justify-content-center")}>
                                <Col xs="7">
                                    <RangeSlider
                                        {...register("Personen")}
                                        value={persons}
                                        tooltipLabel={() => persons + (persons === 1 ? ' Person' : ' Personen')}
                                        onChange={e => setPersons(parseInt(e.target.value))}
                                        min={0}
                                        max={10}
                                        variant='dark'
                                    />
                                </Col>
                                <Col xs="3">
                                    <InputGroup className={c(s.rangeSliderInput)}>
                                        <Form.Control value={persons}
                                                      className={c(s.rangeSliderInput)}
                                                      onChange={(event) => handleInputChange(event)}/>
                                        <InputGroup.Text id="basic-addon2" className={c(s.personen)}>
                                            {persons === 1 ?
                                                <> Person </> :
                                                <> Personen </>
                                            }
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Form.Text className={s.errorMsg}>
                                {errors.Personen?.message}
                            </Form.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={c("justify-content-center")}>
                <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder, "order-md-last")}>
                    <button className={c(s.nextButton, s.button)} type={"submit"}>
                        Weiter
                    </button>
                </Col>
                <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder)}>
                    <div className={c(s.prevButton, s.button)} onClick={prevStep}>
                        Zurück
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default connector(PersonenStep);
