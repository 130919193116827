import * as React from "react"
import c from "classnames"
import s from './Icon.module.scss'

const Icon = (props:any) => {

    return (
        <>
            <div className={c(s.icon)}>
                <img src={props.image} alt=""/>
            </div>
        </>
    );


}

export default Icon;