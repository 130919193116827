import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import s from '../Form/Form.module.scss'
import c from "classnames"
import React, {FC, useEffect, useRef, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {setWohnflaeche} from "../../stores/selection/selectionSlice";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../Services/ApiService";


// @ts-ignore
const mapState = ({selection}) => ({
    selection,
})

const mapDispatch = {
    setWohnflaeche,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface FormInputs {
    BeheizteWohnflaeche: number,
}

let schema = yup.object().shape({
    BeheizteWohnflaeche: yup.number().min(1, 'Bitte wählen Sie die beheizte Wohnfläche aus').required(),
});

const WohnflaecheStep: FC<PropsFromRedux & any> = ({location, selection, setWohnflaeche, prevStep, nextStep}) => {

    const [wohnflaeche, setLocalWohnflaeche] = useState(100);
    const wohnRef = useRef<HTMLInputElement>(null);

    const {register, handleSubmit, formState: {errors}, setValue} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        setValue('BeheizteWohnflaeche', wohnflaeche);
        setWohnflaeche(wohnflaeche)
    }, [wohnflaeche]);

    useEffect(() => {
        if (selection.Wohnflaeche) {
            setLocalWohnflaeche(selection.Wohnflaeche)
        }
    }, [])


    function submitStep(data: any) {
        // @ts-ignore
        window._mtm.push({"event": "step4"});
        nextStep();
    }

    function handleInputChange(event: any) {
        if (event.target.value === '') {
            setLocalWohnflaeche(0)
        } else {
            if (event.target.value > 400 || event.target.value < 0) {
                setLocalWohnflaeche(150);
            } else {
                setLocalWohnflaeche(parseInt(event.target.value))
            }
        }
    }

    const [wohnflaecheTitle, setWohnflaecheTitle] = useState('');
    const [wohnflaecheDesc, setWohnflaecheDesc] = useState('');
    useEffect(() => {
        Client.Raw.post('getText/flaeche').then((res) => {
            setWohnflaecheTitle(res.data.Title);
            setWohnflaecheDesc(res.data.Desc);
        }).catch(handleApiError);
    }, [])

    return (
        <Form onSubmit={handleSubmit(submitStep)}>
            <Row className={c(s.step)}>
                <div ref={wohnRef}/>
                <Col xs={12} md={12}>
                    <Row>
                        <Col xs={12}>
                            <h2 className={c(s.title)}>{wohnflaecheTitle}</h2>
                            <Form.Label className={"col-form-label mb-4"}>
                                {wohnflaecheDesc}
                            </Form.Label>
                        </Col>
                        <Col xs={12}>
                            <Row className={c("justify-content-center")}>
                                <Col xs="7">
                                    <RangeSlider
                                        value={wohnflaeche}
                                        tooltipLabel={() => wohnflaeche + ' m²'}
                                        onChange={e => setLocalWohnflaeche(parseInt(e.target.value))}
                                        step={5}
                                        min={0}
                                        max={400}
                                        variant='dark'
                                    />
                                </Col>
                                <Col xs="3">
                                    <InputGroup className={c(s.rangeSliderInput)}>
                                        <Form.Control
                                            {...register("BeheizteWohnflaeche")}
                                            value={wohnflaeche}
                                            // readOnly={true}
                                            onChange={(event) => handleInputChange(event)}/>
                                        <InputGroup.Text id="basic-addon2" className={c(s.unit)}>m²</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Form.Text className={s.errorMsg}>
                                {errors.BeheizteWohnflaeche?.message}
                            </Form.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={c("justify-content-center")}>
                <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder, "order-md-last")}>
                    <Button className={c(s.nextButton, s.button)} type="submit">
                        Weiter
                    </Button>
                </Col>
                <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder)}>
                    <div className={c(s.prevButton, s.button)} onClick={prevStep}>
                        Zurück
                    </div>
                </Col>
            </Row>
        </Form>

    )
}

export default connector(WohnflaecheStep);
