import {Button, Col, Form, Row} from "react-bootstrap";
import {FC, useEffect, useRef, useState} from "react";
import s from '../Form/Form.module.scss'
import c from "classnames"
import classNames from "classnames/bind";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {setHeizsystem} from "../../stores/selection/selectionSlice";
import {connect, ConnectedProps} from "react-redux";

import fussboden from "./icons/icon_fussbodenheizung.png"
import heizkoerper from "./icons/icon_heizkoerper.png"
import beides from "./icons/icon_heizkoerper+fussboden.png"
import Icon from "../Icon/Icon";
import {Client, handleApiError} from "../../Services/ApiService";

// @ts-ignore
const mapState = ({selection}) => ({
    selection,
})

const mapDispatch = {
    setHeizsystem,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface FormInputs {
    Heizsystem: string
}

let schema = yup.object().shape({
    Heizsystem: yup.string().required('Bitte wählen Sie die Art des Heizsystems aus'),
});

const HeizsystemStep: FC<PropsFromRedux & any> = ({location, selection, setHeizsystem, prevStep, nextStep}) => {

    const [heizsystem, setLocalHeizsystem] = useState('');
    const heizsystemRef = useRef<HTMLInputElement>(null);

    const {register, handleSubmit, formState: {errors}, setValue} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        setValue('Heizsystem', heizsystem);
        setHeizsystem(heizsystem)
    }, [heizsystem]);

    useEffect(() => {
        if (selection.Heizsystem) {
            setLocalHeizsystem(selection.Heizsystem)
        }
    }, [])

    function submitStep(data: any) {
        // @ts-ignore
        window._mtm.push({"event": "step3"});
        nextStep();
    }

    let cl = classNames.bind(s);

    const [heizsystemTitle, setHeizsystemTitle] = useState('');
    const [heizsystemDesc, setHeizsystemDesc] = useState('');
    useEffect(() => {
        Client.Raw.post('getText/heizen').then((res) => {
            setHeizsystemTitle(res.data.Title);
            setHeizsystemDesc(res.data.Desc);
        }).catch(handleApiError);
    }, []);

    return (
        <Form onSubmit={handleSubmit(submitStep)}>
            <Row className={c(s.step)}>
                <div ref={heizsystemRef}/>
                <Col xs={12} md={12}>
                    <Row>
                        <Col xs={12}>
                            <h2 className={c(s.title)}>{heizsystemTitle}</h2>
                            <Form.Label className={"col-form-label mb-4"}>
                                {heizsystemDesc}
                            </Form.Label>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} md={4} lg={4} onClick={() => {
                                    setLocalHeizsystem('2');

                                }}>
                                    <div className={c(s.choice) + ' ' + cl({
                                        active: heizsystem === "2"
                                    })}>
                                        <Icon image={heizkoerper}/>
                                        <div>Heizkörper</div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4} onClick={() => {
                                    setLocalHeizsystem('1');
                                }}>
                                    <div className={c(s.choice) + ' ' + cl({
                                        active: heizsystem === "1"
                                    })}>
                                        <Icon image={fussboden}/>
                                        <div>Fußbodenheizung</div>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} lg={4} onClick={() => {
                                    setLocalHeizsystem('3');
                                }}>
                                    <div className={c(s.choice) + ' ' + cl({
                                        active: heizsystem === "3"
                                    })}>
                                        <Icon image={beides}/>
                                        <div> Heizkörper & Fußbodenheizung</div>
                                    </div>
                                </Col>
                            </Row>
                            <Form.Control type="hidden" value={heizsystem} {...register("Heizsystem")}/>
                            <Form.Text className={s.errorMsg}>
                                {errors.Heizsystem?.message}
                            </Form.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={c("justify-content-center")}>
                <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder, "order-md-last")}>
                    <Button className={c(s.nextButton, s.button)} type="submit">
                        Weiter
                    </Button>
                </Col>
                <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder)}>
                    <div className={c(s.prevButton, s.button)} onClick={prevStep}>
                        Zurück
                    </div>
                </Col>
            </Row>
        </Form>

    )
}

export default connector(HeizsystemStep);
