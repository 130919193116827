import s from './StepMenu.module.scss'
import c from "classnames"
import {createRef, FC, useEffect, useRef, useState} from "react";
import {setMaxStep, setStep} from "../../stores/selection/selectionSlice";
import {connect, ConnectedProps} from "react-redux";

// @ts-ignore
const mapState = ({selection}) => ({
    selection,
})

const mapDispatch = {
    setStep,
    setMaxStep,
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>


const StepMenu: FC<PropsFromRedux & any> = ({location, selection, setStep, setMaxStep}) => {

    const [currentStep, setCurrentLocalStep] = useState(1);
    const [loaded, setLoaded] = useState(false);

    const steps = [
        {
            index: 1,
            title: 'Zustand',
            desc: 'Gebäudezustand'
        },
        {
            index: 2,
            title: 'Heizsystem',
            desc: 'Wärmeabgabe'
        },
        {
            index: 3,
            title: 'Wohnfläche',
            desc: 'Beheizte Fläche'
        },
        {
            index: 4,
            title: 'Personen',
            desc: 'Personen'
        },
        {
            index: 5,
            title: 'Energiebedarf',
            desc: 'Energiebedarf'
        },
        {
            index: 6,
            title: 'Energiepreise',
            desc: 'Preise Anpassen'
        },
        {
            index: 7,
            title: 'Ergebnis',
            desc: 'Ergebnis'
        }
    ];


    const scrollRefs = useRef<any>(Array.from({length: steps.length}, a => createRef()))

    useEffect(() => {
        scrollRefs.current[selection.Step - 1]?.scrollIntoView({behavior: "smooth", inline: "center"})
    }, [selection.Step])

    function getVisited(index: number) {
        if (index < selection.Step) {
            return s.visited
        }
    }

    function getActive(index: number) {
        if (index == selection.Step) {
            return s.active
        }
    }

    function incMaxStep(index: number) {
        if (index >= selection.MaxStep) {
            setMaxStep(index)
        }
    }

    return (
        <>
            <div className={c(s.menuHolder)}>
                <div className={c(s.menu)}>
                    {steps.map((item, index) => {
                        return (
                            <div key={index} className={c(s.holder, getVisited(index + 1), getActive(index + 1))}
                                 onClick={() => {
                                     console.log(currentStep)
                                     if (getVisited(item.index) && currentStep !== 7) {
                                         setCurrentLocalStep(item.index)
                                         setStep(item.index);
                                     }
                                 }}
                                 ref={(ref) => scrollRefs.current[index] = ref}>
                                <div className={c(s.stepNumberHolder)}>
                                    <div className={c(s.stepNumber)}>
                                        {item.index}
                                    </div>
                                    <div className={c(s.stepTitleHolder)}>
                                        <div className={c(s.stepTitle)}>
                                            {item.title}
                                        </div>
                                        <div className={c(s.stepDesc)}>
                                            {item.desc}
                                        </div>
                                    </div>
                                </div>
                                <div className={c(s.triangle)}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default connector(StepMenu);
