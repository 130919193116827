import {
    setBedarfBewusst,
    setCurrentUnit,
    setEnergiebedarf,
    setHeizmaterial
} from "../../stores/selection/selectionSlice";
import {connect, ConnectedProps} from "react-redux";
import classNames from "classnames/bind";
import React, {FC, useEffect, useRef, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import c from "classnames";
import s from "../Form/Form.module.scss";
import Icon from "../Icon/Icon";
import neubau from "./icons/icon_neubau.png";
import sanierung from "./icons/icon_sanierung.png";
import {Client, handleApiError} from "../../Services/ApiService";

import heizoel from "./icons/icon_heizöl.png";
import pellet from "./icons/icon_pellet.png";
import fluessiggas from "./icons/icon_flüssiggas.png";
import strom from "./icons/icon_strom.png";
import erdgas from "./icons/icon_erdgas.png";
import stueckholz from "./icons/icon_stückholz.png";
import ja from "./icons/icon_Ja.png";
import nein from "./icons/icon_Nein.png";

// @ts-ignore
const mapState = ({selection}) => ({
    selection,
})

const mapDispatch = {
    setBedarfBewusst,
    setHeizmaterial,
    setEnergiebedarf,
    setCurrentUnit,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface FormInputs {
    KnowsEnergyRequirment: string,
    Material: string,
    Bedarf: string,
}

let schema = yup.object().shape({
    KnowsEnergyRequirment: yup.string().required('Bitte wählen Sie eine Option aus'),
    Material: yup.string().required('Bitte wählen Sie eine Option aus'),
    Bedarf: yup.string().required('Bitte geben Sie Ihren Energiebedarf an'),
});

const RequirementStep: FC<PropsFromRedux & any> = (
    {
        location,
        selection,
        setBedarfBewusst,
        setHeizmaterial,
        setEnergiebedarf,
        setCurrentUnit,
        prevStep,
        nextStep
    }) => {

    const {register, handleSubmit, formState: {errors}, setValue} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    const [bewusst, setBewusst] = useState('');
    const [material, setMaterial] = useState('Keine Angabe');
    const [bedarf, setBedarf] = useState('0');
    const [bedarfTitle, setBedarfTitle] = useState('');
    const [bedarfDesc, setBedarfDesc] = useState('');
    const [bedarfHeizTitle, setBedarfHeizTitle] = useState('');
    const [bedarfHeizDesc, setBedarfHeizDesc] = useState('');
    const [internalCurrentUnit, setInternalCurrentUnit] = useState('')

    const heizmaterialRef = useRef<HTMLInputElement>(null);
    const energiebedarfRef = useRef<HTMLInputElement>(null);

    const materialsObject = [
        {
            id: '1',
            name: 'heizoel',
            displayName: 'Heizöl',
            unit: 'Liter / Jahr',
            icon: heizoel
        },
        {
            id: '2',
            name: 'erdgas',
            displayName: 'Erdgas',
            unit: 'kWh / Jahr',
            icon: erdgas
        },
        {
            id: '3',
            name: 'fluessiggas',
            displayName: 'Flüssiggas',
            unit: 'kWh / Jahr',
            icon: fluessiggas
        },
        {
            id: '4',
            name: 'pellet',
            displayName: 'Pellet',
            unit: 'kg / Jahr',
            icon: pellet
        },
        {
            id: '5',
            name: 'strom',
            displayName: 'Strom',
            unit: 'kWh / Jahr',
            icon: strom
        },
        {
            id: '6',
            name: 'holz',
            displayName: 'Stückgut (Holz)',
            unit: 'Raummeter / Jahr',
            icon: stueckholz

        }
    ];

    useEffect(() => {
        Client.Raw.post('getText/bedarf').then((res) => {
            setBedarfTitle(res.data.Title);
            setBedarfDesc(res.data.Desc);
            setBedarfHeizTitle(res.data.HeizTitle);
            setBedarfHeizDesc(res.data.HeizDesc);
        }).catch(handleApiError);

        if (selection.Heizmaterial || selection.BedarfBewusst) {
            if (selection.BedarfBewusst) {
                setBewusst(selection.BedarfBewusst)
            }
            if (selection.Heizmaterial) {
                setMaterial(selection.Heizmaterial)
            }
            if (selection.Energiebedarf) {
                setBedarf((selection.Energiebedarf).toLocaleString('de-AT'))
            }
            if (selection.CurrentUnit){
                setInternalCurrentUnit(selection.CurrentUnit)
            }
        }
    }, []);


    useEffect(() => {
        setValue('KnowsEnergyRequirment', bewusst);
        if (!selection.BedarfBewusst || (selection.BedarfBewusst !== bewusst && bewusst !== '')) {
            setBedarfBewusst(bewusst);
        }
    }, [bewusst]);

    useEffect(() => {
        setValue('Material', material);
        if (!selection.Heizmaterial || (selection.Heizmaterial !== material && material !== '')) {
            setHeizmaterial(material)
        }
    }, [material]);


    useEffect(() => {
        setValue('Bedarf', bedarf);
        setEnergiebedarf(parseFloat(bedarf.replace(',', '.')))
    }, [bedarf]);

    useEffect(() => {
        setCurrentUnit(internalCurrentUnit)
    }, [internalCurrentUnit])

    let cl = classNames.bind(s);

    function submitStep(data: any) {
        // @ts-ignore
        window._mtm.push({"event": "step6"});
        nextStep();
    }


    function handleInputChange(event: any) {
        if (event.target.value === '') {
            setBedarf('0');
        } else {
            setBedarf(event.target.value)
        }
    }

    return (
        <Form onSubmit={handleSubmit(submitStep)}>
            <Row className={c(s.step)}>
                <Col xs={12} md={12} className={c("align-self-center")}>
                    <Row>
                        <Col xs={12} className={c("text-center")}>
                            <h2 className={c(s.title)}>
                                {bedarfTitle}
                            </h2>
                            <Form.Label className={c("col-form-label mb-4", s.label)}>
                                {bedarfDesc}
                            </Form.Label>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} md={6} lg={6}>
                                    <div className={c(s.choice) + ' ' + cl({
                                        active: bewusst === 'Ja'
                                    })} onClick={() => {
                                        setBewusst('Ja');
                                        setMaterial('');
                                        setBedarf('0');
                                        heizmaterialRef.current?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "nearest",
                                        });
                                    }}>
                                        <Icon image={ja}/>
                                        <div>Ja</div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={6}>
                                    <div className={c(s.choice) + ' ' + cl({
                                        active: bewusst === 'Nein'
                                    })} onClick={() => {
                                        setBewusst('Nein');
                                        setMaterial('Keine Angabe');
                                        setBedarf('1');
                                        heizmaterialRef.current?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "nearest",
                                        });
                                    }}>
                                        <Icon image={nein}/>
                                        <div>Nein</div>
                                    </div>
                                </Col>
                            </Row>
                            <Form.Control type="hidden" value={bewusst} {...register("KnowsEnergyRequirment")}/>
                            <Form.Text className={s.errorMsg}>
                                {errors.KnowsEnergyRequirment?.message}
                            </Form.Text>
                        </Col>
                    </Row>
                    {
                        bewusst === "Ja" ?
                            <>
                                <div ref={heizmaterialRef}/>
                                <Row className={c(s.step, s.hidden)}>
                                    <Col xs={12} md={12} className="align-self-center">
                                        <Row>
                                            <Col xs={12} className={c("text-center")}>
                                                <Form.Label className={c("col-form-label my-4", s.label)}>
                                                    {bedarfHeizTitle}
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Row className={c("justify-content-center")}>
                                                    {
                                                        materialsObject.map((item, index) => {
                                                            return (
                                                                <Col key={index} xs={12} md={6} lg={4}
                                                                     className={"mb-4"}>
                                                                    <div className={c(s.choice) + ' ' + cl({
                                                                        active: material === item.id
                                                                    })} onClick={() => {
                                                                        setMaterial(item.id);
                                                                        setInternalCurrentUnit(item.unit);
                                                                    }}>
                                                                        <Icon image={item.icon}/>
                                                                        <div>{item.displayName}</div>
                                                                    </div>
                                                                </Col>
                                                            );
                                                        })
                                                    }
                                                </Row>
                                                <Form.Control type="hidden"
                                                              value={material} {...register("Material")}/>
                                                <Form.Text className={s.errorMsg}>
                                                    {errors.Material?.message}
                                                </Form.Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <div ref={heizmaterialRef}/>
                            </>
                    }
                    {
                        material !== 'Keine Angabe' && material !== '' ?
                            <>
                                <div ref={energiebedarfRef}/>
                                <Row className={c(s.step, s.hidden, "justify-content-center")}>
                                    <Col xs={12} md={6} className="align-self-center">
                                        <InputGroup className={c(s.rangeSliderInput)}>
                                            <Form.Control  {...register("Bedarf")} value={bedarf}
                                                           className={c(s.rangeSliderInput)}
                                                           onChange={(event) => handleInputChange(event)}/>
                                            <InputGroup.Text className={c(s.unit)}>
                                                {internalCurrentUnit}
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Form.Text className={c(s.errorMsg)}>
                                            {errors.Bedarf?.message}
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <div ref={energiebedarfRef}/>
                            </>
                    }
                    <Row className={c("justify-content-center")}>
                        <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder, "order-md-last")}>
                            <Button className={c(s.nextButton, s.button)} type="submit">
                                Weiter
                            </Button>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder)}>
                            <div className={c(s.prevButton, s.button)} onClick={prevStep}>
                                Zurück
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}
export default connector(RequirementStep);
