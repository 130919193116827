import React, {FC, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useForm} from "react-hook-form";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import s from './Form.module.scss'
import c from "classnames"
import {Col, Container, Form, Row} from "react-bootstrap";
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
// @ts-ignore
import YearPicker from 'react-single-year-picker';
import {useNavigate} from 'react-router-dom';
import classNames from "classnames/bind";
import 'react-datetime/css/react-datetime.css';
import './slider.scss';
import './yearpicker.scss';
import StepMenu from "../Steps/StepMenu";
import ZustandStep from "../Steps/ZustandStep";
import HeizsystemStep from "../Steps/HeizsystemStep";
import WohnflaecheStep from "../Steps/WohnflaecheStep";
import PersonenStep from "../Steps/PersonenStep";
import {setStep} from "../../stores/selection/selectionSlice";
import {connect, ConnectedProps} from "react-redux";
import Result from "../../Containers/Result/Result";
import {Client, handleApiError} from '../../Services/ApiService';
import RequirementStep from "../Steps/RequirementStep";
import EnergyPriceStep from "../Steps/EnergyPriceStep";


// @ts-ignore
const mapState = ({selection}) => ({
    selection,
})

const mapDispatch = {
    setStep,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface FormInputs {
    Zustand: string,
    BeheizteWohnflaeche: number,
    Baujahr: string,
    Personen: number,
    Heizsystem: string
    HouseType: string
}

let schema = yup.object().shape({
    Zustand: yup.string().required('Bitte wählen Sie den Zustand aus'),
    HouseType: yup.string().required('Bitte Wählen Sie den Bautyp aus'),
    // Baujahr: yup.string().required('Bitte wählen Sie das Baujahr aus'),
    BeheizteWohnflaeche: yup.number().min(1, 'Bitte wählen Sie die beheizte Wohnfläche aus').required(),
    Baujahr: yup.string().optional(),
    Personen: yup.number().min(1, 'Bitte geben Sie die Personenanzahl an').required(),
    Heizsystem: yup.string().required('Bitte wählen Sie die Art des Heizsystems aus'),
});

const FirstStep: FC<PropsFromRedux & any> = ({location, selection, setStep}) => {

    const {register, handleSubmit, formState: {errors}, setValue} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    const [heizlast, setHeizlast] = useState(6969);

    function showCurrentStep() {
        switch (selection.Step) {
            case 1:
                // @ts-ignore
                return <ZustandStep prevStep={() => prevStep()} nextStep={() => nextStep()}/>;
            case 2:
                return <HeizsystemStep prevStep={() => prevStep()} nextStep={() => nextStep()}/>;
            case 3:
                return <WohnflaecheStep prevStep={() => prevStep()} nextStep={() => nextStep()}/>;
            case 4:
                return <PersonenStep prevStep={() => prevStep()} nextStep={() => nextStep()}/>;
            case 5:
                return <RequirementStep prevStep={() => prevStep()} nextStep={() => nextStep()}/>
            case 6:
                return <EnergyPriceStep prevStep={() => prevStep()} nextStep={() => nextStep()}/>
            case 7:
                return <Result/>
            default:
                // @ts-ignore
                return <ZustandStep prevStep={() => prevStep()}/>;
        }
    }

    function nextStep() {
        if (selection.Step !== 7) {
            setStep(selection.Step + 1)
        }
    }

    function prevStep() {
        if (selection.Step !== 1) {
            setStep(selection.Step - 1)
        }
    }

    return (
        <>
            <StepMenu setDisplayStep={setStep}/>
            <Container className={c(s.heizkostenHolder)}>
                <div className={c(s.form)}>
                    {showCurrentStep()}
                </div>
            </Container>
        </>
    );

}

export default connector(FirstStep);


