import React from 'react';
import './App.scss';
import Main from "./Containers/Main/Main";
import {HashRouter as Router} from "react-router-dom";

function App() {
    return (
        <>
            <Router>
                <Main/>
            </Router>
        </>
    );
}

export default App;
