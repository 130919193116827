import React, {FC, useEffect, useRef, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {
    setPreisErgas,
    setPreisFluessiggas,
    setPreisHeizoel,
    setPreisPellet,
    setPreisStrom
} from "../../stores/selection/selectionSlice";
import {Client, handleApiError} from "../../Services/ApiService";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import c from "classnames";
import s from "../Form/Form.module.scss";
import classNames from "classnames/bind";

// @ts-ignore
const mapState = ({selection}) => ({
    selection,
})

const mapDispatch = {
    setPreisHeizoel,
    setPreisErgas,
    setPreisFluessiggas,
    setPreisPellet,
    setPreisStrom,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface FormInputs {
    HeizoelPreis: string,
    ErdgasPreis: string,
    PelletPreis: string,
    FluessiggasPreis: string,
    StromPreis: string,
}

let schema = yup.object().shape({
    HeizoelPreis: yup.string().optional(),
    ErdgasPreis: yup.string().optional(),
    PelletPreis: yup.string().optional(),
    FluessiggasPreis: yup.string().optional(),
    StromPreis: yup.string().optional(),
});

const EnergyPriceStep: FC<PropsFromRedux & any> = (
    {
        location,
        selection,
        setPreisHeizoel,
        setPreisErgas,
        setPreisFluessiggas,
        setPreisPellet,
        setPreisStrom,
        prevStep,
        nextStep
    }) => {

    const {register, handleSubmit, formState: {errors}, setValue} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    const [heizoelPreis, setHeizoelPreis] = useState('0');
    const [erdgasPreis, setErdgasPreis] = useState('0');
    const [fluessiggasPreis, setFluessiggasPreis] = useState('0');
    const [pelletPreis, setPelletPreis] = useState('0');
    const [stromPreis, setStromPreis] = useState('0');

    const [energieTitle, setEnergieTitle] = useState('');
    const [energieDesc, setEnergieDesc] = useState('');

    const preiseRef = useRef<HTMLInputElement>(null);

    const getCurrentDateFormatted = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd: number = today.getDate();

        return (((dd < 10) ? ('0' + dd) : dd) + '.' + ((mm < 10) ? ('0' + mm) : mm) + '.' + yyyy);
    };

    useEffect(() => {
        Client.Raw.post('getText/price').then((res) => {
            setEnergieTitle(res.data.Title);
            setEnergieDesc(res.data.Desc.split('{yesterday}')[0] + ' ' + getCurrentDateFormatted() + ' ' + res.data.Desc.split('{yesterday}')[1]);
            setHeizoelPreis(res.data.PreisHeizoel.toLocaleString('de-AT'));
            setErdgasPreis(res.data.PreisErdgas.toLocaleString('de-AT'));
            setFluessiggasPreis(res.data.PreisFluessiggas.toLocaleString('de-AT'));
            setPelletPreis(res.data.PreisPellets.toLocaleString('de-AT'));
            setStromPreis(res.data.PreisStrom.toLocaleString('de-AT'));
        }).catch(handleApiError).then(() => {
            if (selection.PreisHeizoel && parseFloat(heizoelPreis.replace(',', '.')) <= 0) {
                setHeizoelPreis((selection.PreisHeizoel).toLocaleString('de-AT'))
            }
            if (selection.PreisErgas && parseFloat(erdgasPreis.replace(',', '.')) <= 0) {
                setErdgasPreis((selection.PreisErgas).toLocaleString('de-AT'))
            }
            if (selection.PreisFluessiggas && parseFloat(fluessiggasPreis.replace(',', '.')) <= 0) {
                setFluessiggasPreis((selection.PreisFluessiggas).toLocaleString('de-AT'))
            }
            if (selection.PreisPellet && parseFloat(pelletPreis.replace(',', '.')) <= 0) {
                setPelletPreis((selection.PreisPellet).toLocaleString('de-AT'))
            }
            if (selection.PreisStrom && parseFloat(stromPreis.replace(',', '.')) <= 0) {
                setStromPreis((selection.PreisStrom).toLocaleString('de-AT'))
            }
        });
    }, []);

    useEffect(() => {
        setPreisHeizoel(parseFloat(heizoelPreis.replace(',', '.')))
    }, [heizoelPreis]);

    useEffect(() => {
        setPreisErgas(parseFloat(erdgasPreis.replace(',', '.')))
    }, [erdgasPreis]);

    useEffect(() => {
        setPreisFluessiggas(parseFloat(fluessiggasPreis.replace(',', '.')))
    }, [fluessiggasPreis]);

    useEffect(() => {
        setPreisPellet(parseFloat(pelletPreis.replace(',', '.')))
    }, [pelletPreis]);

    useEffect(() => {
        setPreisStrom(parseFloat(stromPreis.replace(',', '.')))
    }, [stromPreis]);

    function submitStep(data: any) {
        // ['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']
        // @ts-ignore
        window._mtm.push({"event": "step7"});
        nextStep();
    }

    let cl = classNames.bind(s);


    const priceArray = [
        {
            name: 'Heizöl',
            suffix: 'EUR/L',
            source: 'https://iwo-austria.at/heizoelpreis/',
            registerName: "HeizoelPreis" as "HeizoelPreis",
            errorMsg: errors.HeizoelPreis?.message,
            pricePointer: heizoelPreis,
            callbackFunc: (value: any) => {
                setHeizoelPreis(value)
            }
        },
        {
            name: 'Erdgas',
            suffix: 'EUR/kWh',
            source: 'https://www.e-control.at/konsumenten/strom/strompreis/was-kostet-eine-kwh-gas',
            registerName: "ErdgasPreis" as "ErdgasPreis",
            errorMsg: errors.ErdgasPreis?.message,
            pricePointer: erdgasPreis,
            callbackFunc: (value: any) => {
                setErdgasPreis(value)
            }
        },
        {
            name: 'Flüssiggas',
            suffix: 'EUR/L',
            source: 'https://www.fluessiggas-gemeinsam-kaufen.de/fluessiggaspreise-aktuell-jahresvergleich',
            registerName: "FluessiggasPreis" as "FluessiggasPreis",
            errorMsg: errors.FluessiggasPreis?.message,
            pricePointer: fluessiggasPreis,
            callbackFunc: (value: any) => {
                setFluessiggasPreis(value)
            }
        },
        {
            name: 'Pellets',
            suffix: 'EUR/kg',
            source: 'https://www.propellets.at/aktuelle-pelletpreise',
            registerName: "PelletPreis" as "PelletPreis",
            errorMsg: errors.PelletPreis?.message,
            pricePointer: pelletPreis,
            callbackFunc: (value: any) => {
                setPelletPreis(value)
            }
        },
        {
            name: 'Strom',
            suffix: 'EUR/kWh',
            source: 'https://www.e-control.at/konsumenten/strom/strompreis/was-kostet-eine-kwh',
            registerName: "StromPreis" as "StromPreis",
            errorMsg: errors.StromPreis?.message,
            pricePointer: stromPreis,
            callbackFunc: (value: any) => {
                setStromPreis(value)
            }
        }
    ];

    return (
        <Form onSubmit={handleSubmit(submitStep)}>
            <Row className={c(s.step)}>
                <Col xs={12} md={12} className={c("align-self-center")}>
                    <Row>
                        <Col xs={12} className={c("text-center")}>
                            <h2 className={c(s.title)}>
                                {energieTitle}
                            </h2>
                            <Form.Label className={c("col-form-label mb-4", s.label)}>
                                {energieDesc}
                            </Form.Label>
                        </Col>
                        {
                            priceArray.map((item, index) => {
                                return (
                                    <Col key={index} xs={12} md={6} className="align-self-center pb-5 text-start">
                                        <Form.Text className={c(s.priceTitle)}>
                                            {item.name}
                                        </Form.Text>
                                        <InputGroup className={c(s.rangeSliderInput)}>
                                            <Form.Control  {...register(item.registerName)}
                                                           value={item.pricePointer}
                                                           className={c(s.rangeSliderInput)}
                                                           onChange={(event) => {
                                                               item.callbackFunc(event.target.value)
                                                           }}
                                            />
                                            <InputGroup.Text className={c(s.unit)}>
                                                {item.suffix}
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Form.Text style={{fontSize: '12px', fontWeight: 700}}>
                                            Quelle: <a style={{fontWeight: 400, color: '#020202'}} href={item.source}
                                                       target={'_blank'}>{item.source}</a>
                                        </Form.Text>
                                        <Form.Text className={c(s.errorMsg)}>
                                            {item.errorMsg}
                                        </Form.Text>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <Row className={c("justify-content-center")}>
                        <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder, "order-md-last")}>
                            <Button className={c(s.nextButton, s.button)} type="submit">
                                Weiter
                            </Button>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={3} className={c(s.buttonHolder)}>
                            <div className={c(s.prevButton, s.button)} onClick={prevStep}>
                                Zurück
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default connector(EnergyPriceStep);