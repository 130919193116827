import axios, {AxiosError, AxiosResponse} from "axios";
import {toast} from "react-toastify";


const baseURL = process.env.REACT_APP_API_BASE_URL;
const client = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export const handleApiError = (error: AxiosError) => {
    if (error.response?.data) {
        if (typeof error.response.data?.messages === "object") {
            error.response.data?.messages.forEach((message: any) => {
                toast.error(message.message);
            });
        } else if (typeof error.response.data?.message === "string") {
            toast.error(error.response.data.message);
        } else if (error.response.data.toString().length <= 64) {
            toast.error(error.response.data);
        } else {
            toast.error('Es ist ein unbekannter Fehler aufgetreten.');
        }

        // if ((typeof error.response.data?.action === "string") && error.response.data.action === 'LOGOUT') {
        //     window.location.replace('/logout');
        // }
    }
}

export interface ApiMessage {
    message: string
}

export const Client = {
    Raw: client,
}
