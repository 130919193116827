import * as React from "react";
// @ts-ignore
import style from './FullscreenLoading.module.scss';
import {SquareLoader} from "react-spinners";
import {FC} from "react";

type LoadingProps = {
    loading: boolean
}

const FullscreenLoading:FC<LoadingProps> = ({loading}) => {
    return (
        <div className={style.holder}>
            <SquareLoader loading={loading} color='#E30613' size={50}/>
        </div>
    )
}

export default FullscreenLoading;
