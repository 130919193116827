import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface AuthState {
    Step: number,
    MaxStep: number,
    Zustand: string,
    ZustandZusatz: string,
    Baujahr: string,
    Heizsystem: string,
    Wohnflaeche: string,
    Personenanzahl: string,
    BedarfBewusst: string,
    Heizmaterial: string,
    Energiebedarf: number,
    PreisHeizoel: number,
    PreisErgas: number,
    PreisFluessiggas: number,
    PreisPellet: number,
    PreisStrom: number,
    CurrentUnit: string,
}

// Define the initial state using that type
const initialState: AuthState = {
    Step: 1,
    MaxStep: 1,
    Zustand: '',
    ZustandZusatz: '',
    Baujahr: '',
    Heizsystem: '',
    Wohnflaeche: '100',
    Personenanzahl: '2',
    BedarfBewusst: '',
    Heizmaterial: 'Keine Angabe',
    Energiebedarf: 0,
    PreisHeizoel: 0,
    PreisErgas: 0,
    PreisFluessiggas: 0,
    PreisPellet: 0,
    PreisStrom: 0,
    CurrentUnit: '',
}

export const selectionSlice = createSlice({
    name: 'selection',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setStep: (state: any, action: PayloadAction<any>) => {
            state.Step = action.payload
        },
        setMaxStep: (state: any, action: PayloadAction<any>) => {
            state.MaxStep = action.payload
        },
        setZustand: (state: any, action: PayloadAction<any>) => {
            state.Zustand = action.payload
        },
        setZustandZusatz: (state: any, action: PayloadAction<any>) => {
            state.ZustandZusatz = action.payload
        },
        setBaujahr: (state: any, action: PayloadAction<any>) => {
            state.Baujahr = action.payload
        },
        setHeizsystem: (state: any, action: PayloadAction<any>) => {
            state.Heizsystem = action.payload
        },
        setWohnflaeche: (state: any, action: PayloadAction<any>) => {
            state.Wohnflaeche = action.payload
        },
        setPersonenanzahl: (state: any, action: PayloadAction<any>) => {
            state.Personenanzahl = action.payload
        },
        setBedarfBewusst: (state: any, action: PayloadAction<any>) => {
            state.BedarfBewusst = action.payload
        },
        setHeizmaterial: (state: any, action: PayloadAction<any>) => {
            state.Heizmaterial = action.payload
        },
        setEnergiebedarf: (state: any, action: PayloadAction<any>) => {
            state.Energiebedarf = action.payload
        },
        setPreisHeizoel: (state: any, action: PayloadAction<any>) => {
            state.PreisHeizoel = action.payload
        },
        setPreisErgas: (state: any, action: PayloadAction<any>) => {
            state.PreisErgas = action.payload
        },
        setPreisFluessiggas: (state: any, action: PayloadAction<any>) => {
            state.PreisFluessiggas = action.payload
        },
        setPreisPellet: (state: any, action: PayloadAction<any>) => {
            state.PreisPellet = action.payload
        },
        setPreisStrom: (state: any, action: PayloadAction<any>) => {
            state.PreisStrom = action.payload
        },
        setCurrentUnit: (state: any, action: PayloadAction<any>) => {
            state.CurrentUnit = action.payload
        },
        resetState: (state: any) => {
            state.Step = 1;
            state.MaxStep = 1;
            state.Zustand = '';
            state.ZustandZusatz = '';
            state.Baujahr = '';
            state.Heizsystem = '';
            state.Wohnflaeche = '';
            state.Personenanzahl = '';
            state.BedarfBewusst = '';
            state.Heizmaterial = 'Keine Angabe';
            state.Energiebedarf = 0;
            state.PreisHeizoel = 0;
            state.PreisErgas = 0;
            state.PreisFluessiggas = 0;
            state.PreisPellet = 0;
            state.PreisStrom = 0;
            state.CurrentUnit = '';
        }
    },
})

export const {
    setStep,
    setMaxStep,
    setZustand,
    setZustandZusatz,
    setBaujahr,
    setHeizsystem,
    setWohnflaeche,
    setPersonenanzahl,
    setBedarfBewusst,
    setHeizmaterial,
    setEnergiebedarf,
    setPreisHeizoel,
    setPreisErgas,
    setPreisFluessiggas,
    setPreisPellet,
    setPreisStrom,
    setCurrentUnit,
    resetState
} = selectionSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default selectionSlice.reducer
